import { useLoading } from 'hooks/useLoading'
import styled from 'styled-components'

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from '@ionic/react'

import { useMutation } from '@apollo/client'
import { playCircleOutline } from 'ionicons/icons'
import { useForm } from 'react-hook-form'
import { Select } from '../../components/form/Select'
import { Textarea } from '../../components/form/Textarea'
import { graphql } from '../../gql'
import { updateTimeClockQueryInCache } from '../../helpers/apollo/updateTimeClockQueryInCache'
import { TimeClockTasks } from '../../hooks/timeclock/useTimeClockTasks'
import { useToast } from '../../hooks/useToast'
import { TimesheetClockInInput } from '../../schema'
import { modalWithTasks } from './helpers/modalWithTasks'
import { useTimeClockMutationErrorHandler } from './helpers/useTimeClockMutationErrorHandler'
import { useGeoLocation } from './hooks/useGeolocation'

const ClockInMutation = graphql(`
  mutation ClockIn($data: TimesheetClockInInput!) {
    timeClock {
      clockIn(data: $data) {
        timeClock {
          ...UseTimeClock_TimeClock
        }
      }
    }
  }
`)

const ClockInButton = styled(IonButton).attrs(() => {
  const attrs = {
    children: <>
      <IonIcon slot="start" icon={playCircleOutline} />
      Clock In
    </>,
    size: 'medium',
  }
  return attrs
})`
  margin-top: 30px;
  min-width: 50%;
`

const ErrorMessage = styled.span`
  color: var(--ion-color-danger, #ff0000);
  margin: 0.6em 0;
  font-size: 0.8em;
  font-weight: 700;
`

interface FormValues {
  task?: number
  note: string
}

type ClockInModalProps = {
  onDismiss?: () => void
}

const ClockInModalWithTasks = ({ onDismiss, tasks }: ClockInModalProps & { tasks: TimeClockTasks }) => {
  const { withLoading } = useLoading()
  const [presentToast] = useToast()
  const { getCurrentPosition } = useGeoLocation({ actionText: 'clock in' })

  const {
    control, watch, handleSubmit, formState: { errors },
  } = useForm<FormValues>()

  const [clockIn] = useMutation(ClockInMutation, { update: updateTimeClockQueryInCache })
  const mutationErrorHandler = useTimeClockMutationErrorHandler()

  const selectedTaskId = watch('task')
  const selectedTask = tasks.find((task) => task.id === selectedTaskId)
  const inNoteRequired = selectedTask?.isNoteRequired
  const isGeoRequired = selectedTask?.isGeoRequired

  const saveData = async (data: TimesheetClockInInput) => {
    try {
      const { errors: mutationErrors } = await clockIn({
        variables: {
          data,
        },
      })

      mutationErrorHandler(mutationErrors, { throw: true })

      presentToast({
        color: 'success',
        message: 'You have been clocked in.',
        duration: 3000,
      })

      if (onDismiss) {
        onDismiss()
      }
    } catch (err: any) {
      presentToast({
        color: 'danger',
        message: `An error occured, please try again: ${err.message}`,
        duration: 4000,
      })
    }
  }

  const onFormSubmit = async (data: FormValues) => {
    const input: TimesheetClockInInput = {
      note: data.note,
      task: data.task ? {
        id: data.task,
      } : undefined,
    }
    if (isGeoRequired) {
      const position = await getCurrentPosition()
      if (!position) return
      input.geolocation = position.coords
    }
    return withLoading(saveData)(input)
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const FormErrorMessage = ({ field }: { field: keyof FormValues }) => {
    const msg = errors[field]?.message
    if (msg) {
      return <ErrorMessage>{msg}</ErrorMessage>
    }
    return null
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Clock In</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}> Close </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <IonList>
            {tasks.length > 0 && (
              <IonItem>
                <IonLabel position="stacked">Task</IonLabel>
                <FormErrorMessage field="task" />
                <Select
                  name="task"
                  control={control}
                  rules={{
                    required: 'Please select what task you are working on.',
                  }}
                  placeholder="Select current task"
                  interface="action-sheet"
                  interfaceOptions={{
                    header: 'Select a task',
                  }}
                >
                  {tasks?.map((task) => (
                    <IonSelectOption key={task.name} value={task.id}>{task.name}</IonSelectOption>
                  ))}
                </Select>
              </IonItem>
            )}
            <IonItem>
              <IonLabel position="stacked">Comments {inNoteRequired ? '( required )' : undefined}</IonLabel>
              <FormErrorMessage field="note" />
              <Textarea
                autoGrow
                name="note"
                control={control}
                placeholder="Purpose of clock in"
                rules={inNoteRequired ? {
                  required: 'This task requires you add a comment for this clock in.',
                } : undefined}
              />
            </IonItem>
          </IonList>

          <div className="ion-text-center">
            <ClockInButton type="submit" />
          </div>
        </form>
      </IonContent>
    </>
  )
}

export const ClockInModal = modalWithTasks(ClockInModalWithTasks)
