import { TimeClock } from 'hooks/timeclock/useTimeClock'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

type FutureClockInProps = Partial<Pick<TimeClock, 'startTime'>>

const ClockTime = styled.div`
  margin-top: 5px;
  font-weight: 500;
`

export const FutureClockIn = (props: FutureClockInProps) => {
  const { startTime } = props

  const diff = useMemo(() => (
    startTime?.diffNow().as('milliseconds')
  ), [startTime])

  const [isFutureClockIn, setIsFutureClockIn] = useState<boolean>(Boolean(diff && diff > 0))

  useEffect(() => {
    if (!diff) return
    const timeout = setTimeout(() => {
      setIsFutureClockIn(false)
    }, diff + 5000)
    return () => clearTimeout(timeout)
  }, [diff])

  if (!isFutureClockIn) return null
  if (!startTime) return null

  return (
    <div>
      <small>Clocked in as of</small>
      <ClockTime>{startTime.toLocal().toFormat('LL/dd hh:mma')}</ClockTime>
    </div>
  )
}
