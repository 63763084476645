import { IonTextarea } from '@ionic/react'
import React, { useEffect, useRef } from 'react'
import { UseControllerProps, useController } from 'react-hook-form'

export type TextareaProps =
  UseControllerProps<any, any> &
  Omit<React.ComponentProps<typeof IonTextarea>, 'value'>

export const Textarea = (props: TextareaProps) => {
  const {
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
    ...selectProps
  } = props

  const ref = useRef<HTMLIonTextareaElement | null>(null)
  const {
    field: {
      ref: formFieldRef, onChange, onBlur, value,
    },
  } = useController({
    control,
    defaultValue: defaultValue !== undefined ? defaultValue : '',
    name,
    rules,
    shouldUnregister,
  })

  useEffect(() => {
    formFieldRef({
      focus: () => { ref.current?.setFocus() },
    })
  }, [formFieldRef])

  return (
    <IonTextarea
      {...selectProps}
      ref={ref}
      value={value}
      onIonBlur={(evt) => {
        onBlur()
        if (selectProps.onIonBlur) {
          selectProps.onIonBlur(evt)
        }
      }}
      onIonChange={(evt) => {
        onChange(evt.detail.value)
        if (selectProps.onIonChange) {
          selectProps.onIonChange(evt)
        }
      }}
    />
  )
}
