import React, { PropsWithChildren } from 'react'

import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react'

export type StandardLayoutProps = PropsWithChildren<{
  title?: React.ReactNode
  id?: string
  hideHeader?: boolean
  headerEndButtons?: React.ReactNode
  collapsingTitle?: boolean
  defaultBackTo?: string
}>

export const StandardLayout = (props: StandardLayoutProps) => (
  <>
    {props.hideHeader !== true && (
      <IonHeader translucent>
        <IonToolbar style={{ '--border-width': '0px' }}>
          <IonButtons slot="start">
            <IonBackButton defaultHref={props.defaultBackTo} />
          </IonButtons>
          <IonTitle>{props.title}</IonTitle>
          {props.headerEndButtons && (
            <IonButtons slot="end">
              {props.headerEndButtons}
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
    )}
    <IonContent fullscreen>
      {props.hideHeader !== true && props.collapsingTitle && (
        <IonHeader collapse="condense">
          <IonToolbar>
            {props.title &&
              <IonTitle size="large">{props.title}</IonTitle>}
          </IonToolbar>
        </IonHeader>
      )}

      {props.children}
    </IonContent>
  </>
)
