import {
  IonSkeletonText,
} from '@ionic/react'

export interface SkeletonParagraphProps extends React.ComponentProps<typeof IonSkeletonText> {
  rows?: number
  width?: string
  firstWidth?: string
  lastWidth?: string
}

const defaults: SkeletonParagraphProps = {
  rows: 4,
  width: '100%',
}

export const SkeletonParagraph = (props: SkeletonParagraphProps) => {
  const {
    rows,
    width,
    firstWidth,
    lastWidth,
    ...rest
  } = {
    ...defaults,
    ...props,
  }

  return (
    <>
      {Array(rows).fill(true).map((_, i) => (
        <IonSkeletonText
          key={i}
          {...rest}
          style={{
            margin: '10px 0',
            ...rest.style,
            width: i === 0 ? firstWidth || width : (i + 1) === rows ? lastWidth || width : width,
          }}
        />
      ))}
    </>
  )
}
