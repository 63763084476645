import {
  IonButton,
  IonCard,
  IonCheckbox, IonCol,
  IonGrid, IonIcon, IonRow,
} from '@ionic/react'
import { AssignmentDateBadge } from 'components/assignments/AssignmentDateBadge'
import { DetailsWrap } from 'components/assignments/details/DetailsWrap'
import { EquipmentDetail } from 'components/assignments/details/EquipmentDetail'
import { LeaveTimeDetail } from 'components/assignments/details/LeaveTimeDetail'
import { InlineErrorMessage } from 'components/common/ErrorMessage'
import { Markdown } from 'components/common/Markdown'
import { SkeletonParagraph } from 'components/common/SkeletonParagraph'
import gql from 'graphql-tag'
import * as analytics from 'helpers/analytics'
import { assignmentDate } from 'helpers/assignmentDate'
import { assignmentStatus } from 'helpers/assignmentStatus'
import { daySuffix } from 'helpers/datetime'
import { groupInventoryAcrossOrders } from 'helpers/groupInventoryAcrossOrders'
import { byFieldPathAsc } from 'helpers/sortting'
import { useSession } from 'hooks/useSession'
import { call as callIcon } from 'ionicons/icons'
import { compact } from 'lodash'
import { ComponentProps, useEffect, useMemo } from 'react'
import { Controller as ControllerRaw, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Assignment } from 'schema'
import styled from 'styled-components'
import { RecursivePartial } from 'types/RecursivePartial'
import { useFeature } from '../../hooks/useFeatures'
import { useToast } from '../../hooks/useToast'
import { useAcknowledgeAssignmentMutation, useGetAssignmentCardFullDataQuery } from './__generated__/AssignmentCardFull'
import { ReportTimeDetail } from './details/ReportTimeDetail'

// :any casting is here to get around this issue:
// https://github.com/react-hook-form/react-hook-form/issues/6679
const Controller: any = ControllerRaw

const Card = styled(IonCard)`
  margin-top: 5px;
  font-size: 15px;
  line-height: 1.5em;
`

const StatusText = styled.div`
  font-size: 22px;
  color: var(--vulcan-color-current-status);
  font-weight: 600;
  margin-top: 10px;
  letter-spacing: 1px;
`

const InfoSection = styled((props: ComponentProps<typeof IonCol>) => {
  const {
    title, className, children, ...rest
  } = props

  return (
    <IonRow className={className}>
      <IonCol size="12" {...rest}>
        {title && <div className="emphasize">{title}</div>}
        {children}
      </IonCol>
    </IonRow>
  )
})`
  white-space: pre-wrap;

  ul {
    margin: 5px 0 0 0;
  }
`

const AckButton = styled(IonButton).attrs((props) => ({
  children: props.children || 'Acknowledge Assignment',
}))`
  display: block;
  margin-left: var(--ion-padding, 16px);
  margin-right: var(--ion-padding, 16px);
  margin-bottom: var(--ion-padding, 16px);
`

const DetailsConfirmButton = styled(IonButton).attrs({
  color: 'light',
})`
  font-size: 0.95em;
  font-weight: 600;
  letter-spacing: -0.1px;
  white-space: pre-wrap;
  height: auto;

  --padding-top: 8px;
  --padding-bottom: 8px;
  --padding-start: 12px;
  --padding-end: 18px;
  --border-width: 1px;
  --border-style: solid;
  --border-color: var(--ion-color-step-200);
  --border-radius: 3px;

  ion-checkbox {
    margin-right: 15px;
    --size: 15px;
    --checkmark-width: 4px;
  }

  &.errors {
    --border-color: var(--ion-color-danger);
    --border-width: 2px;
    --border-style: solid;

    ion-checkbox {
      --border-color: var(--ion-color-danger);
    }
  }
`

const DetailsConfirmButtonInner = styled.span`
  text-align: left;
  line-height: 1.5em;
  color: var(--ion-color-step-800);
`

const MarkdownStyled = styled(Markdown)`
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 3px;
  }
`

const ReportTimeBanner = styled(ReportTimeDetail).attrs({
  size: 'banner',
})`
  margin: 8px 0;
  opacity: 0.9;
`

const AssignmentHeaderCol = styled(IonCol).attrs({
  className: 'ion-align-self-center',
})`
  min-width: 0;
  flex: 1 0 0px;
  padding: 0 10px;
`

gql`
  fragment AssignmentCardFullData on Assignment {
    id
    active
    acknowledged
    acknowledgedAt

    operator {
      id
      firstName
      lastName
    }

    route {
      id
      instructions
      startTimeLocal
      endTimeLocal
      status
      active

      equipment {
        id
        name
      }

      assignments {
        id
        active
        roleDetails {
          slug
          name
          sort
        }
        operator {
          id
          firstName
          lastName

          phones {
            id
            number
          }
        }
      }

      waypoints {
        id
        scheduledArrivalTime

        order {
          id
          status
          instructions
          linesOnSite

          site {
            id
            name
            address {
              ...AddressFieldsSlim
            }
          }

          planned {
            id
            inventory {
              id
              quantity
              item {
                id
                name
                consumable
                displayUnit
                visibility {
                  operatorAssignment
                }
              }
            }
          }
        }
      }
    }
  }
`

gql`
  query GetAssignmentCardFullData($assignmentId: Int!) {
    assignment(id: $assignmentId) {
      ...AssignmentCardFullData
    }
  }
`

gql`
  mutation AcknowledgeAssignment(
    $where: AcknowledgeAssignmentInput!
  ) {
    acknowledgeAssignment(where: $where) {
      id
      acknowledged
      acknowledgedAt
    }
  }
`

const TeamAssignmentListItem = (
  {
    assignment,
    hidePhone,
  }: {
    assignment: RecursivePartial<Assignment>
    hidePhone?: boolean
  }
) => {
  const { operator } = assignment
  const phone = operator?.phones?.[0]?.number

  const role = ['lead', 'support'].includes(assignment.roleDetails?.slug || '') ? undefined : assignment.roleDetails?.name

  return (
    <li>
      {compact([operator?.firstName, operator?.lastName]).join(' ')}
      {role && ` - (${role})`}

      {!hidePhone && phone && (
        <a
          style={{
            marginLeft: '10px',
            fontSize: '0.9em',
          }}
          className="no-style"
          href={`tel:${phone}`}
        >
          <IonIcon src={callIcon} />
        </a>
      )}
    </li>
  )
}

export interface AssignmentCardFullProps {
  id: Assignment['id']
  setTitle?: (title: string) => void
}

export const AssignmentCardFull = ({ id, setTitle }: AssignmentCardFullProps) => {
  const history = useHistory()
  const { user } = useSession()
  const [presentToast] = useToast()
  const [reportAtTimeEnabled] = useFeature('assignment.showReportAtTime')

  const { data, loading: loadingRaw, error } = useGetAssignmentCardFullDataQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      assignmentId: id,
    },
  })

  const [acknowledgeAssignmentMutation] = useAcknowledgeAssignmentMutation({
    variables: {
      where: {
        id,
      },
    },
  })

  const ackForm = useForm({ mode: 'onSubmit' })

  const assignment = data?.assignment
  const route = assignment?.route
  const loading = !assignment && loadingRaw

  const datetime = useMemo(() => assignmentDate(assignment), [assignment])
  const status = assignment ? assignmentStatus(assignment) : undefined

  useEffect(() => {
    if (!assignment) return
    if (!user) return
    if (user.id !== assignment.operator.id) {
      presentToast({
        color: 'warning',
        message: 'That assignment does not exist.',
        duration: 4000,
      })
      history.replace('/assignments')
    }
  }, [user, assignment])

  // TODO: gross, don't do this, some thoughts:
  // react teleporter (issue is multiple layouts render at once cause ionic nav)?
  // context?
  // separate query in parent?
  useEffect(() => {
    if (datetime && setTitle) {
      setTitle(datetime.toFormat('ccc, LLL d') + daySuffix(datetime))
    }
  }, [datetime, setTitle])

  const isCancelled = status?.slug === 'cancelled'
  const needsAcknowledgement = status?.needsAcknowledgement

  const orders = useMemo(() => (
    compact((route?.waypoints || []).map((waypoint) => waypoint.order))
  ), [route?.waypoints])

  const inventory = useMemo(() => groupInventoryAcrossOrders(orders), [orders])

  const instructions = useMemo(() => (
    compact(
      orders.map((order, index) => {
        if (!order.instructions) return

        if (orders.length > 1) {
          return `**Stop #${index + 1}**  \n${order.instructions}`
        }
        return order.instructions
      })
    ).join('\n\n')
  ), [orders])

  const linesOnSite = useMemo(() => compact(orders?.map((order, index) => {
    if (order.linesOnSite) {
      return compact([
        `Stop #${index + 1}`,
        order.site?.name && `at ${order?.site?.name}`,
        'has lines on site',
      ]).join(' ')
    }
    return null
  })).join('\n'), [orders])

  const detailsConfirmButtonText = useMemo(() => (
    compact([
      linesOnSite && 'I am aware there will be lines on site',
      instructions && 'I have reviewed the instructions',
    ]).join('\n')
  ), [Boolean(linesOnSite), Boolean(instructions)])

  const visibleAssignments = useMemo(() => {
    if (!assignment) {
      return []
    }

    if (assignment.active && route) {
      return route.assignments.slice().sort(byFieldPathAsc(['roleDetails', 'sort']))
    }

    return [{
      ...assignment,
      operator: {
        ...assignment.operator,
        phones: [],
      },
    }]
  }, [assignment, route?.assignments])

  const ackFormErrors = Object.values(ackForm.formState.errors).map(({ message }) => message)

  const onAckFormSubmit = async () => {
    analytics.trackClick({ name: 'OperatorAcknowledgedAssignment' })

    try {
      await acknowledgeAssignmentMutation()

      presentToast({
        color: 'success',
        message: 'Thanks for acknowledging.',
        duration: 4000,
      })
    } catch (err: any) {
      presentToast({
        color: 'danger',
        message: `An error occured, please try again: ${err.message}`,
        duration: 4000,
      })
    }
  }

  if (error) {
    return <InlineErrorMessage message={`An error occured: ${error}`} />
  }

  const notFound = loading === false && !assignment
  if (notFound) {
    return <InlineErrorMessage message="An error occured while fetching the assigment" />
  }

  return (
    <>
      <Card className={`status-${status?.slug}`}>
        <IonGrid className="ion-no-padding ion-block">
          <IonRow className="ion-nowrap">
            <IonCol size="auto">
              <AssignmentDateBadge loading={loading} status={status?.slug} datetime={datetime} style={{ borderBottomRightRadius: 'var(--ion-border-radius)', height: '100%' }} />
            </IonCol>
            <AssignmentHeaderCol>
              {isCancelled && (
                <StatusText>Cancelled</StatusText>
              )}
              {reportAtTimeEnabled && (
                <ReportTimeBanner loading={loading} assignment={assignment} />
              )}
              <DetailsWrap style={{ opacity: 0.9, width: '100%' }}>
                <LeaveTimeDetail loading={loading} assignment={assignment} size={isCancelled ? 'small' : 'medium'} />
                <EquipmentDetail loading={loading} equipment={route?.equipment} size={isCancelled ? 'small' : 'medium'} />
              </DetailsWrap>
            </AssignmentHeaderCol>
          </IonRow>
        </IonGrid>

        <IonGrid className="ion-padding" style={{ paddingTop: '8px' }}>
          {loading && (
            <InfoSection>
              <SkeletonParagraph animated width="60%" firstWidth="40%" />
            </InfoSection>
          )}

          {linesOnSite && (
            <InfoSection title="Lines on Site">
              {linesOnSite}
            </InfoSection>
          )}

          {instructions && !isCancelled && (
            <InfoSection title="Instructions">
              <MarkdownStyled children={instructions} />
            </InfoSection>
          )}

          {needsAcknowledgement && !isCancelled && Boolean(detailsConfirmButtonText) && (
            <InfoSection>
              <Controller
                name="instructionsAck"
                control={ackForm.control}
                rules={{
                  required: 'You must confirm you have reviewed the details above.',
                }}
                render={({ field }: any) => (
                  <div className="ion-text-center">
                    <DetailsConfirmButton
                      className={ackForm.formState.errors.instructionsAck ? 'errors' : undefined}
                      onClick={() => {
                        ackForm.setValue(field.name, true, { shouldValidate: true })
                      }}
                    >
                      <IonCheckbox
                        slot="start"
                        checked={field.value}
                        onIonChange={(e) => {
                          ackForm.setValue(field.name, e.detail.checked)
                        }}
                      />
                      <DetailsConfirmButtonInner>{detailsConfirmButtonText}</DetailsConfirmButtonInner>
                    </DetailsConfirmButton>
                  </div>
                )}
              />
            </InfoSection>
          )}

          {visibleAssignments.length > 0 && (
            <InfoSection title="Team">
              <ul>
                {visibleAssignments.map((anAssignment) => (
                  <TeamAssignmentListItem
                    key={anAssignment.id}
                    assignment={anAssignment}
                    hidePhone={anAssignment.operator?.id === assignment?.operator.id}
                  />
                ))}
              </ul>
            </InfoSection>
          )}

          {inventory.length > 0 && (
            <InfoSection title="Inventory">
              <ul>
                {inventory.map(({ quantity, item }) => (
                  <li key={item.id}>
                    {compact([item.name, quantity && `${quantity}${item.displayUnit || 'x'}`]).join(' - ')}
                  </li>
                ))}
              </ul>
            </InfoSection>
          )}
        </IonGrid>
      </Card>

      {status?.needsAcknowledgement && (
        <form onSubmit={ackForm.handleSubmit(onAckFormSubmit)}>
          {ackFormErrors.length > 0 && (
            <div className="emphasize ion-padding ion-text-center smaller" style={{ color: 'var(--ion-color-danger)' }}>
              {ackFormErrors.join('\n')}
            </div>
          )}

          <AckButton type="submit" children={isCancelled ? 'Acknowledge Cancellation' : undefined} />
        </form>
      )}
    </>
  )
}
