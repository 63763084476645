import { IonCard } from '@ionic/react'
import { Loading } from 'components/common/Loading'
import React from 'react'

interface LoadingCardProps extends React.ComponentProps<typeof IonCard> {
  loader?: React.ComponentProps<typeof Loading>
}

export const LoadingCard = ({ loader, ...rest }: LoadingCardProps) => (
  <IonCard {...rest}>
    <Loading enabled {...loader} />
  </IonCard>
)
