import { byFieldPathAsc } from 'helpers/sortting'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { IonIcon } from '@ionic/react'
import { useLoading } from 'hooks/useLoading'

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonTitle,
  IonToolbar,
} from '@ionic/react'

import { Select, SelectOption } from 'components/form/Select'
import { Signature } from 'components/form/Signature'
import { useForm } from 'react-hook-form'

import { ModalButton } from 'components/common/ModalButton'
import gql from 'graphql-tag'
import { checkmarkCircleOutline } from 'ionicons/icons'
import { useToast } from '../hooks/useToast'
import { TermsAndConditionsModal } from './TermsAndConditionsModal'
import { useCollectOrderSignatureMutation, useGetCustomerContactsQuery, useGetOrderDataForSignatureQuery } from './__generated__/CustomerSignatureModal'

const SubmitButton = styled(IonButton).attrs(() => ({
  children: 'Submit',
  size: 'medium',
  type: 'submit',
}))`
  margin-top: 10px;
  min-width: 70%;
`

const ErrorMessage = styled.span`
  color: var(--ion-color-danger, #ff0000);
  margin: 0.6em 0;
  font-size: 0.8em;
  font-weight: 700;
`

const TermsOfServiceButton = styled(ModalButton).attrs((props) => {
  const title = props.title || 'Terms & Conditions'

  return {
    title,
    content: <span className="tncwrap">
      By clicking submit, you acknowledge you have <br /> read and agree to the <span className="tnclink">{title}</span>
    </span>,
    fill: 'clear',
    size: 'small',
  }
})`
  --padding-top: 5px;
  --padding-bottom: 5px;
  --padding-end: 20px;
  --padding-start: 20px;
  min-height: 44px;
  margin-top: 15px;
  opacity: 1 !important;

  .tncwrap {
    white-space: normal !important;
    color: var(--ion-color-step-700, #4d4d4d);
    min-height: 44px;
    font-size: 13px;
    line-height: 17px;
  }

  .tnclink {
     color: #3880ff;
  }
`

gql`
  mutation CollectOrderSignature($data: CollectSignatureInput!, $where: OrderWhereUniqueInput!) {
    collectOrderSignature(data: $data, where: $where) {
      id
    }
  }
`

gql`
  fragment OrderDataForSignature on Order {
    id
    customer {
      id
    }
    contacts {
      id
      default
      contact {
        id
        firstName
        lastName
      }
      role {
        name
        slug
        sort
      }
    }
    terms {
      id
      accepted
      needsAcceptance
      content {
        id
        title
        body
      }
    }
  }
`

gql`
  query GetOrderDataForSignature($orderId: Int!) {
    order(id: $orderId) {
      ...OrderDataForSignature
    }
  }
`

gql`
  query GetCustomerContacts($customerId: Int!) {
    customers(where: { id: { equals: $customerId } } ) {
      id
      contacts(where: { active: { equals: true } } ) {
        id
        firstName
        lastName
      }
    }
  }
`

interface FormValues {
  personId?: number
  image: string
}

export const CustomerSignatureModal = ({
  id,
  onDismiss,
}: {
  id: number,
  onDismiss?: () => void
}) => {
  const { withLoading } = useLoading()
  const [presentToast] = useToast()
  const [completed, setCompleted] = useState<boolean>(false)

  const form = useForm<FormValues>()
  const { control, handleSubmit, formState: { errors } } = form

  const orderQuery = useGetOrderDataForSignatureQuery({
    variables: { orderId: id },
  })
  const order = orderQuery?.data?.order
  const orderContacts = order?.contacts || []
  const customerId = order?.customer?.id

  const terms = orderQuery?.data?.order?.terms
  const title = terms?.content?.title || 'Terms & Conditions'

  const customerContactsQuery = useGetCustomerContactsQuery({
    skip: customerId === undefined,
    variables: { customerId: customerId || -1 },
  })
  const customerContacts = customerContactsQuery?.data?.customers?.[0]?.contacts || []

  const [collectOrderSignatureMutation] = useCollectOrderSignatureMutation({
    refetchQueries: 'active',
  })

  const error = orderQuery?.error || customerContactsQuery?.error

  useEffect(() => {
    if (error) {
      presentToast({
        color: 'danger',
        message: `An error occured while fetching contacts: ${error.message}`,
        duration: 4000,
      })
    }
  }, [error])

  const contacts = useMemo(() => {
    const orderContactsSorted = orderContacts.slice().sort((a, b) => {
      if (a.role?.slug === 'field') return -1
      if (b.role?.slug === 'field') return 1
      if (a.default) return -1
      if (b.default) return 1
      return (
        byFieldPathAsc(['role', 'sort'])(a, b) ||
        byFieldPathAsc(['person', 'firstName'])(a, b) ||
        byFieldPathAsc(['person', 'firstName'])(a, b)
      )
    }).map(({ contact }) => contact)

    const contactIds = orderContactsSorted.map((c) => c.id)

    const customerContactsSorted = customerContacts
      .filter((contact) => !contactIds.includes(contact.id))
      .sort((a, b) => (
        byFieldPathAsc(['person', 'firstName'])(a, b) ||
        byFieldPathAsc(['person', 'firstName'])(a, b)
      ))

    return orderContactsSorted.concat(customerContactsSorted)
  }, [orderContacts, customerContacts])

  useEffect(() => {
    if (!form.getValues('personId')) {
      form.setValue('personId', contacts[0]?.id)
    }
  }, [contacts])

  const onFormSubmit = withLoading(async (data: FormValues) => {
    try {
      const mutationResponse = await collectOrderSignatureMutation({
        variables: {
          data,
          where: { id },
        },
      })

      if (mutationResponse.errors) {
        throw new Error(mutationResponse.errors.map((err) => err.message).join('').replace(/[\r\n]+/g, ' '))
      }

      setCompleted(true)
    } catch (err: any) {
      presentToast({
        color: 'danger',
        message: `An error occured, please try again: ${err.message}`,
        duration: 4000,
      })
    }
  })

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {title}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}> Close </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {!completed && (
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <IonList>
              <IonItem>
                <IonLabel position="fixed">Name</IonLabel>
                <Select
                  style={{ flexGrow: 1, maxWidth: '100%' }}
                  name="personId"
                  control={control}
                  interface="action-sheet"
                >
                  {contacts.map((contact) => (
                    <SelectOption key={contact.id} value={contact.id}>{contact.firstName} {contact.lastName}</SelectOption>
                  ))}
                </Select>
              </IonItem>
              <IonItem>
                <div style={{ minWidth: '100%' }}>
                  {errors.image &&
                    <ErrorMessage>{errors.image.message}</ErrorMessage>}

                  <Signature
                    name="image"
                    control={control}
                    rules={{
                      required: 'Please sign in the box below',
                    }}
                    style={{
                      width: '100%',
                    }}
                  />
                </div>
              </IonItem>
            </IonList>

            <div className="ion-text-center">
              <TermsOfServiceButton title={title}>
                <TermsOfServiceButton.Modal>
                  {terms ? <TermsAndConditionsModal terms={terms.content} /> : <IonLoading isOpen />}
                </TermsOfServiceButton.Modal>
              </TermsOfServiceButton>

              <SubmitButton />
            </div>
          </form>
        )}
        {
          completed && (
            <div className="ion-padding ion-text-center" style={{ height: '100%', width: '100%' }} onClick={onDismiss}>
              <div>
                <IonIcon icon={checkmarkCircleOutline} style={{ fontSize: '80px', color: 'var(--ion-color-success, #2fdf75)', marginTop: '10%' }} />
              </div>
              <div style={{ fontSize: '30px', margin: '20px 0 15px' }}>
                Signature Accepted
              </div>

              <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                Please return this device to the Operator.
              </div>
            </div>
          )
        }
      </IonContent>
    </>
  )
}
