import { ResultOf } from '@graphql-typed-document-node/core'
import {
  IonCard,
  IonChip, IonItem, IonLabel,
  IonSkeletonText,
  IonSpinner,
} from '@ionic/react'
import { AccordionGroup } from 'components/common/Accordion'
import { DateTime, Duration } from 'luxon'
import { useMemo } from 'react'

import { TIME_SHIFT_HOURS_FORMAT } from 'helpers/datetime'
import { graphql } from '../../gql'

import TimeActivitySummary from './detail/TimeActivitySummary'
import TimeClockDetail from './detail/TimeClockDetail'

const TimeSheetFragment = graphql(`
  fragment TimeSheetHistory_TimeSheet on TimeSheet {
    id
    shifts {
      id
      startTime
      endTime
      timeClocks {
        id
        startTime
        endTime
        task {
          id
          name
          sort
          color
        }
      }
    }
  }
`)

interface HoursByTask {
  [key: number]: {
    name: string
    time: number
    color: string
    sort: number
  }
}

export type TimeSheetHistoryProps = {
  timeSheet: ResultOf<typeof TimeSheetFragment>,
  loading: boolean,
}

export const TimeSheetHistory = ({ timeSheet, loading }: TimeSheetHistoryProps) => {
  const { shifts } = timeSheet

  const totalWorkedHours = useMemo(() => {
    if (shifts.length > 0) {
      return shifts.reduce((acc, shift) => {
        const shiftStartTime = DateTime.fromISO(shift.startTime)
        const shiftEndTime = shift.endTime ? DateTime.fromISO(shift.endTime) : DateTime.now()
        return acc + shiftEndTime.diff(shiftStartTime, 'minutes').minutes
      }, 0)
    }
    return 0
  }, [shifts])

  const totalHoursByTask = useMemo(() => {
    if (shifts.length > 0) {
      return shifts.reduce((acc, shift) => {
        shift.timeClocks.forEach((timeClock) => {
          const { task } = timeClock
          const taskEndTime = timeClock.endTime ? DateTime.fromISO(timeClock.endTime) : DateTime.now()
          const duration = taskEndTime.diff(DateTime.fromISO(timeClock.startTime), 'minutes').minutes
          if (task && task.id) {
            acc[task.id] = {
              name: task.name,
              color: task.color,
              sort: task.sort,
              time: (acc[task.id]?.time || 0) + duration,
            }
          }
        })
        return acc
      }, {} as HoursByTask)
    }
    return {} as HoursByTask
  }, [shifts])

  if (shifts.length === 0) {
    return (
      <IonCard>
        <h6 className="ion-padding ion-text-center">
          There are no time cards that match the date range.
        </h6>
      </IonCard>
    )
  }

  return (
    <>
      <IonItem lines="none">
        <IonLabel>
          <h2><strong>Total Hours</strong></h2>
        </IonLabel>

        {loading ?
          <IonSpinner name="lines" color="primary" />
          :
          (
            <IonChip color="success" slot="end">
              <strong>{Duration.fromObject({ minutes: totalWorkedHours }).toFormat(TIME_SHIFT_HOURS_FORMAT)} </strong>
            </IonChip>
          )}

      </IonItem>

      {loading ?
        (
          <IonItem lines="none">
            <IonSpinner name="lines" color="primary" />
          </IonItem>
        )
        :
        (
          Object.keys(totalHoursByTask).length > 0 && (
            <IonItem lines="none">
              <TimeActivitySummary hoursByTask={totalHoursByTask} />
            </IonItem>
          )
        )}

      <IonItem lines='none'>
        <IonLabel>
          <h2><strong>History</strong></h2>
        </IonLabel>
      </IonItem>

      {loading ?
        (
          <IonItem lines='none'>
            <IonSkeletonText animated style={{ height: '2.5em' }} />
          </IonItem>
        )
        :
        (
          <AccordionGroup>
            {shifts.map((shift) => (
              <TimeClockDetail key={`shift-${shift.id}`} shift={shift} />
            ))}
          </AccordionGroup>
        )}
    </>
  )
}
