import React from 'react'
import styled from 'styled-components'

import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from '@ionic/react'
import { StandardLayout } from 'layouts/StandardLayout'

const Title = styled.div`
  margin-bottom: 15px;
  margin-top: 5px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
`

const VerticallyCentered = ({ children }: React.PropsWithChildren<any>) => (
  <IonGrid style={{ height: '100%' }}>
    <IonRow style={{
      display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%',
    }}
    >
      <IonCol size="12">
        {children}
      </IonCol>
    </IonRow>
  </IonGrid>
)

type LoginLayoutProps = React.PropsWithChildren<{
  id: string
  backTo?: string
}>

export const LoginLayout = ({ id, backTo, children }: LoginLayoutProps) => (
  <IonPage id={id}>
    <StandardLayout defaultBackTo={backTo} hideHeader={Boolean(backTo) === false}>
      <IonContent fullscreen className="ion-padding ion-text-center">
        <VerticallyCentered>
          <div style={{ minHeight: '140px' }}>
            <img src="https://res.cloudinary.com/vulcan/image/upload/cretesuite/logo.svg" width="260px" />
          </div>
          <Title>Crew Login</Title>

          <div style={{ minHeight: '270px', marginBottom: backTo ? '46px' : undefined }}>
            {children}
          </div>
        </VerticallyCentered>
      </IonContent>
    </StandardLayout>
  </IonPage>
)

export default LoginLayout
