import {
  IonCard, IonCardContent, IonCol, IonGrid, IonRow,
} from '@ionic/react'
import { InlineErrorMessage } from 'components/common/ErrorMessage'
import { LoadingCard } from 'components/common/LoadingCard'
import { MoreIcon } from 'components/common/MoreIcon'
import { Link } from 'components/links/Link'
import gql from 'graphql-tag'
import { formatRelativeTime, parseDatetime } from 'helpers/datetime'
import { formatAddress } from 'helpers/formatAddress'
import { linkForWaypointDetails } from 'helpers/links/linkForWaypointDetails'
import { useRouteDate } from 'hooks/useRouteDate'
import { compact, pick } from 'lodash'
import React, { useMemo, useRef } from 'react'
import { RouteWaypoint } from 'schema'
import styled from 'styled-components'
import { useGetWaypointCardSlimDataQuery } from './__generated__/WaypointCard'

const ContentWrap = styled(IonCardContent)`
  display: flex;
  font-size: 15px;
  padding: 16px;

  > * {
    flex: 0 0 auto;
    min-width: 0;
  }
`

const Details = styled.div`
  flex: 1 1 0;
  min-width: 0;
`

const Schedule = styled(IonGrid)`
  padding: 0;
  margin-top: 10px;
  font-size: 14px;
  --ion-grid-column-padding: 0;

  > * {
    margin-bottom: 2px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const LabelCol = styled(IonCol)`
  font-weight: var(--vulcan-emphasized-weight);
  flex-basis: 58px;
  margin-right: 8px;
  flex-grow: 0;
  flex-shrink: 1;
`

gql`
  fragment WaypointCardSlimData on RouteWaypoint {
    id
    status
    name
    scheduledArrivalTimeLocal
    scheduledDepartureTimeLocal
    actualArrivalTimeLocal
    actualDepartureTimeLocal
    estimatedArrivalTimeLocal
    routeId

    address {
      ...AddressFieldsSlim
    }
    order {
      id
      status

      customer {
        id
        abbreviation
        name
      }
    }
  }
`

gql`
  query GetWaypointCardSlimData($waypointId: Int!) {
    routeWaypoint(id: $waypointId) @client {
      ...WaypointCardSlimData
    }
  }
`

export interface WaypointCardProps extends Omit<React.ComponentProps<typeof IonCard>, 'id'> {
  id: RouteWaypoint['id']
  link?: string | false
}

export const WaypointCard = ({ id, link, ...cardProps }: WaypointCardProps) => {
  const { data: dataRaw, loading: loadingRaw, error } = useGetWaypointCardSlimDataQuery({
    fetchPolicy: 'cache-first',
    variables: {
      waypointId: id,
    },
  })

  // apollo-cache-persist doesn't work with @client fields it seems, causing a "blip"
  // where apollo reports loading + undefined data after initially loading
  const dataRef = useRef(dataRaw)
  if (dataRaw) {
    dataRef.current = dataRaw
  }
  const data = dataRef.current

  const waypoint = data?.routeWaypoint
  const loading = !waypoint && loadingRaw

  const routeDate = useRouteDate(waypoint?.routeId)

  const arrival = useMemo(() => {
    if (!waypoint) return null

    let date: string
    let label: string

    if (waypoint.actualArrivalTimeLocal) {
      date = waypoint.actualArrivalTimeLocal
      label = 'Arrived'
    } else if (waypoint.scheduledArrivalTimeLocal) {
      date = waypoint.scheduledArrivalTimeLocal
      label = 'Arrive'
    } else {
      return null
    }

    const datetime = parseDatetime(date)

    return {
      label,
      display: formatRelativeTime(datetime, (routeDate || datetime).toLocal()),
      date: datetime,
    }
  }, [waypoint, routeDate])

  const departure = useMemo(() => {
    if (!waypoint) return null

    let date: string
    let label: string

    if (waypoint.actualDepartureTimeLocal) {
      date = waypoint.actualDepartureTimeLocal
      label = 'Departed'
    } else if (waypoint.scheduledDepartureTimeLocal) {
      date = waypoint.scheduledDepartureTimeLocal
      label = 'Depart'
    } else {
      return null
    }

    const datetime = parseDatetime(date)

    return {
      label,
      display: formatRelativeTime(datetime, (routeDate || datetime).toLocal()),
      date: datetime,
    }
  }, [waypoint, routeDate])

  if (error) {
    return <InlineErrorMessage message={`An error occured: ${error}`} />
  }

  const notFound = loading === false && !waypoint
  if (notFound) {
    return <InlineErrorMessage message="An error occured while fetching the assigment" />
  }

  if (!waypoint) {
    return <LoadingCard {...cardProps} />
  }

  const moreLink = link !== undefined ? link : linkForWaypointDetails(waypoint)

  const waypointName = compact([
    waypoint.order?.customer?.abbreviation || waypoint?.order?.customer?.name,
    waypoint.name,
  ]).join(' | ')

  return (
    <IonCard {...cardProps}>
      <Link to={moreLink || undefined} className="no-style">
        <ContentWrap>
          <Details>
            <div className="emphasize larger">{waypointName}</div>
            {formatAddress(pick(waypoint.address, ['city', 'state']))}

            <Schedule>
              {arrival && (
                <IonRow>
                  <LabelCol>{arrival.label}</LabelCol>
                  <IonCol>{arrival.display}</IonCol>
                </IonRow>
              )}
              {departure && (
                <IonRow>
                  <LabelCol>{departure.label}</LabelCol>
                  <IonCol>{departure.display}</IonCol>
                </IonRow>
              )}
            </Schedule>
          </Details>
          {moreLink && <MoreIcon />}
        </ContentWrap>
      </Link>
    </IonCard>
  )
}
