import { IonCard, IonIcon } from '@ionic/react'
import highVoltage from 'assets/images/high-voltage.svg'
import styled from 'styled-components'

export const LinesOnSiteCallout = styled(IonCard).attrs({
  children: <>
    <IonIcon icon={highVoltage} />
    Lines on Site
  </>,
})`
  background-color: var(--vulcan-color-status-cancelled);
  color: #fff;
  padding: 6px 30px;
  font-size: 18px;
  letter-spacing: 0.05em;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin-top: 8px;

  ion-icon {
    font-size: 1.2em;
    margin-right: 8px;
    vertical-align: bottom;
  }
`
