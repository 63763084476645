import { DateTime, Duration } from 'luxon'
import {
  PropsWithChildren, createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useServiceWorker } from './hooks/useServiceWorker'

type PWAManagerProps = PropsWithChildren<object>

const MINIMUM_UPDATE_CHECK_DURATION = Duration.fromObject({ minutes: 5 })
const AUTOMATIC_UPDATE_CHECK_DURATION = Duration.fromObject({ minutes: 15 })

type PWAUpdate = {
  available: false
  load?: null
} | {
  available: true
  load: () => Promise<void>
}

type PWAManagerState = {
  update: PWAUpdate
}

export const PWAManagerContext = createContext<PWAManagerState>({ update: { available: false } })

const lastCheck = new class LastCheckStore {
  _key = 'cs_sw_last_refresh'
  get = () => {
    const storedKey = localStorage.getItem(this._key)
    if (storedKey) {
      const asDateTime = DateTime.fromISO(storedKey)
      if (asDateTime.isValid) {
        return asDateTime
      }
    }
    const now = DateTime.utc()
    this.set(now)
    return now
  }
  set = (dateTime: DateTime) => {
    localStorage.setItem(this._key, dateTime.toISO())
  }
}()

export const PWAManager = (props: PWAManagerProps) => {
  const serviceWorkerRegistration = useServiceWorker('service-worker.js')
  const [update, setUpdate] = useState<PWAUpdate>({ available: false })

  const checkForUpdate = useCallback(() => {
    if (!serviceWorkerRegistration) return
    const now = DateTime.utc()
    const durationSinceLastCheck = now.diff(lastCheck.get())
    if (durationSinceLastCheck <= MINIMUM_UPDATE_CHECK_DURATION) {
      return
    }
    lastCheck.set(now)
    serviceWorkerRegistration.update()
  }, [serviceWorkerRegistration])

  useEffect(() => {
    // check for updates on load
    checkForUpdate()
    // check for updates on interval
    const intervalId = setInterval(checkForUpdate, AUTOMATIC_UPDATE_CHECK_DURATION.toMillis())
    // check for updates on focus
    const onFocus = () => setTimeout(checkForUpdate, 100)
    window.addEventListener('focus', onFocus)
    return () => {
      if (intervalId) clearInterval(intervalId)
      window.removeEventListener('focus', onFocus)
    }
  }, [checkForUpdate])

  useEffect(() => {
    const waitingForInstall = serviceWorkerRegistration?.waiting?.state === 'installed'
    if (!waitingForInstall) return

    // Once a new service worker is loaded and is in the
    // waiting state, set that an update is available
    setUpdate({
      available: true,
      load: () => (
        new Promise((resolve, reject) => {
          if (serviceWorkerRegistration?.waiting?.state === 'installed') {
            // event triggers after the new serviceWorker takes over
            navigator.serviceWorker.addEventListener('controllerchange', () => {
              resolve()
            })
            // tell the new service worker to become active
            serviceWorkerRegistration?.waiting.postMessage({ type: 'SKIP_WAITING' })
          } else if (serviceWorkerRegistration.active?.state === 'activated') {
            // the update was already make active by another tab
            resolve()
          } else {
            reject()
          }
        })
      ),
    })
  }, [serviceWorkerRegistration?.waiting])

  const value = useMemo(() => ({ update }), [update])

  return <PWAManagerContext.Provider value={value}>{props.children}</PWAManagerContext.Provider>
}
