import React from 'react'

import { useSession } from 'hooks/useSession'

import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRefresher,
  IonRefresherContent,
  useIonRouter,
} from '@ionic/react'

import { Loading } from 'components/common/Loading'

import { VerticalFlex } from 'components/common/VerticalFlex'
import { logOutOutline, navigateOutline } from 'ionicons/icons'
import styled from 'styled-components'
import { GreetingHeader } from '../../components/common/GreetingHeader'
import { useNavigationAppActionSheet } from '../../hooks/useNavigationAppActionSheet'

const Icon = styled(IonIcon)`
  margin-right: 10px;
`

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProfileIndexProps { }

type OnIonRefreshFn = NonNullable<React.ComponentProps<typeof IonRefresher>['onIonRefresh']>

export const ProfileIndex = (props: ProfileIndexProps) => {
  const { user } = useSession()
  const router = useIonRouter()
  const { present: presentNavigationActionSheet } = useNavigationAppActionSheet()

  const loading = false

  const onIonRefresh: OnIonRefreshFn = (event) => {
    event.detail.complete()
  }

  return (
    <>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={onIonRefresh}>
          <IonRefresherContent />
        </IonRefresher>

        <VerticalFlex>
          <div>
            <GreetingHeader />

            <Loading enabled={loading} />

            <IonList>
              <IonItem onClick={() => presentNavigationActionSheet()}>
                <Icon icon={navigateOutline} /> <IonLabel>Set Navigation App</IonLabel>
              </IonItem>
              <IonItem onClick={() => router.push('/auth/logout', 'root', 'replace')}>
                <Icon icon={logOutOutline} /><IonLabel>Logout</IonLabel>
              </IonItem>
            </IonList>
          </div>
        </VerticalFlex>
      </IonContent>
    </>
  )
}
