import { assign, pick } from 'lodash'
import { ValueOf } from 'type-fest'
import { useOrganization } from './useOrganization'

const TENANTS = {
  solidground: 1,
  pks: 14,
  sandbox: 15,
  cagle: 16,
  decker: 23,
  ohana: 28,
  keim: 359,
  training: 522,
  miller: 621,
  westernAustin: 687,
}

const DEFAULT_FEATURES = {
  'order.allowOperatorToCancel': true,
  'timeclocks.enabled': true,
  'assignment.showReportAtTime': true,
  'order.showFullAddress': true,
  'workTicket.requireSignature': false,
  'assignment.hideDetailsTillAcknowledgement': false,
} as const

type Features = Partial<Record<keyof typeof DEFAULT_FEATURES, boolean>>

type TENANT_ID = ValueOf<typeof TENANTS>

const TENANT_FEATURES: Record<TENANT_ID, Features | undefined> = {
  [TENANTS.solidground]: {
    'order.showFullAddress': true,
  },
  [TENANTS.pks]: {
    'order.allowOperatorToCancel': false,
  },
  [TENANTS.sandbox]: {
    'timeclocks.enabled': true,
    'assignment.showReportAtTime': true,
    'order.showFullAddress': true,
    'workTicket.requireSignature': true,
  },
  [TENANTS.cagle]: {
    'timeclocks.enabled': true,
  },
  [TENANTS.decker]: {
    'timeclocks.enabled': true,
  },
  [TENANTS.ohana]: {
    'timeclocks.enabled': true,
  },
  [TENANTS.keim]: {
    'assignment.showReportAtTime': true,
    'order.showFullAddress': true,
    'assignment.hideDetailsTillAcknowledgement': true,
  },
  [TENANTS.training]: {
    'assignment.showReportAtTime': true,
  },
  [TENANTS.westernAustin]: {
    'assignment.showReportAtTime': true,
    'order.allowOperatorToCancel': false,
    'workTicket.requireSignature': true,
  },
}

const organizationUsesLegacyDepartAt = (id: TENANT_ID | undefined) => id && id <= 589

const organizationUsesLegacyHiddenAddress = (id: TENANT_ID | undefined) => id && id < 654

const organizationDefaultsToTimeclocksDisabled = (id: TENANT_ID | undefined) => id && id <= 98

type FeatureMetadata = {
  loading: boolean
}

export function useFeatures<
  T extends keyof Features,
  R extends { [K in T]: boolean }
>(
  ...names: T[]
): [R, FeatureMetadata] {
  const organization = useOrganization()

  const features = Object.fromEntries(names.map((k) => [k, false])) as R

  assign(features, pick(DEFAULT_FEATURES, names))

  if ('assignment.showReportAtTime' in features && organizationUsesLegacyDepartAt(organization?.id)) {
    features['assignment.showReportAtTime'] = false
  }

  if ('order.showFullAddress' in features && organizationUsesLegacyHiddenAddress(organization?.id)) {
    features['order.showFullAddress'] = false
  }

  if ('timeclocks.enabled' in features && organizationDefaultsToTimeclocksDisabled(organization?.id)) {
    features['timeclocks.enabled'] = false
  }

  const tenantFeatures = TENANT_FEATURES[organization?.id || -1]
  if (tenantFeatures) {
    assign(features, pick(tenantFeatures, names))
  }

  return [features, { loading: !organization }]
}

export function useFeature(name: keyof Features): [boolean, FeatureMetadata] {
  const [features, metadata] = useFeatures(name)
  return [features[name], metadata]
}
