import { useLoading } from 'hooks/useLoading'

import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react'

import { useCreation } from 'ahooks'
import { sortBy } from 'lodash'
import { DateTime } from 'luxon'
import { FC, useEffect, useMemo } from 'react'
import { TimeClockTasks, useTimeClockTasks } from '../../../hooks/timeclock/useTimeClockTasks'

type HOCProps = {
  tasks: TimeClockTasks
}

export const modalWithTasks = <P extends Record<any, any>>(Component: FC<P & HOCProps>) => (props: P) => {
  const { showLoading, hideLoading } = useLoading()

  const asOf = useCreation(() => DateTime.utc().toISO(), [])
  const { data, error, loading } = useTimeClockTasks({
    variables: {
      noteRequired: {
        asOf,
      },
      geoRequired: {
        asOf,
      },
    },
  })
  const tasks = useMemo(() => sortBy(data || [], 'sort'), [data])

  useEffect(() => {
    if (loading) {
      showLoading()
    } else {
      hideLoading()
    }
  }, [loading])

  if (!loading) {
    return <Component {...props} tasks={tasks} />
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Error</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={props.onDismiss}> Close </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {error && (
          <>
            <IonCard>
              <IonCardContent>
                Tasks could not be loaded. Please ensure you are online. If this issue persists, please contact your admistrator.
                {error.message}
              </IonCardContent>
            </IonCard>

            <IonButton onClick={props.onDismiss} expand='block'>Close</IonButton>
          </>
        )}
      </IonContent>
    </>
  )
}
