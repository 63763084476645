import { IonFab, IonFabButton, IonIcon } from '@ionic/react'
import { settingsSharp } from 'ionicons/icons'
import { useState } from 'react'
import { SettingsMenu } from './SettingsMenu'

export const SettingsFab = () => {
  const [open, setOpen] = useState<boolean | undefined>(undefined)
  const toggle = () => setOpen((prev) => !prev)

  return (
    <>
      <IonFab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        onClick={toggle}
      >
        <IonFabButton color="light" size="small">
          <IonIcon icon={settingsSharp} />
        </IonFabButton>
      </IonFab>

      <SettingsMenu open={open} onClose={() => setOpen(false)} />
    </>
  )
}
