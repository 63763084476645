import { Terms } from 'schema'

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/react'

export const TermsAndConditionsModal = ({
  terms,
  onDismiss,
}: {
  terms: Pick<Terms, 'title' | 'body'>,
  onDismiss?: () => void
}) => (
  <>
    <IonHeader>
      <IonToolbar>
        <IonTitle>{terms.title}</IonTitle>
        <IonButtons slot="end">
          <IonButton onClick={onDismiss}> Close </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
    <IonContent fullscreen className="ion-padding">
      {terms.body}
      <IonButton onClick={onDismiss} expand="block" className="ion-margin-top"> Close </IonButton>
    </IonContent>
  </>
)
