import React, {
  ComponentProps, CSSProperties, ReactNode, useMemo,
  useRef, useState,
} from 'react'

import { useUpdateEffect } from 'ahooks'

import { IonInput, IonItem } from '@ionic/react'
import styled from 'styled-components'
import { CollapseContent } from './Collapse'

const ItemStyled = styled(IonItem) <{ noPlaceholder?: boolean }>`
  --min-height: 70px;

  ${(props) => props.noPlaceholder && `
    --min-height: 38px;
  `}

  .collapse-trigger {
    pointer-events: auto !important;
  }
`

const NonInteractiveInput = styled(IonInput).attrs({
  readonly: true,
}) <{ visible?: boolean }>`
  top: 0;
  position: absolute;
  transition: opacity 0.3s ease-in-out, z-index 0s linear 0.3s;
  opacity: 1;
  // font-size: 0.9em;

  > input:placeholder-shown {
    text-overflow: ellipsis;
  }

  ${(props) => (props.visible === false) && `
    opacity: 0;
    z-index: 0;
    pointer-events: none;
  `}
`

const CollapseContentStyled = styled(CollapseContent)`
  width: 100%;
  position: relative;
  padding-bottom: 5px;

  ion-item {
    &:before {
      content: "○";
      font-size: 0.7em;
      position: absolute;
      z-index: 2;
      margin-top: 0.9em;
    }
    --padding-start: 18px;
  }
`

const AnimationWrapper = styled.div`
  position: relative;
  width: 100%;
`

export type CollapseItemProps = ComponentProps<typeof IonItem> & {
  placeholder?: string
  value?: string
  defaultOpen?: boolean
  header?: ReactNode | ((open: boolean) => ReactNode)
}

export const CollapseItem = (props: CollapseItemProps) => {
  const [open, setOpen] = useState(props.defaultOpen || false)
  const [maxHeight, setMaxHeight] = useState<CSSProperties['maxHeight']>(open ? 'fit-content' : '0')
  const ref = useRef<HTMLDivElement>(null)

  useUpdateEffect(() => {
    if (open) {
      setMaxHeight((ref.current?.scrollHeight || 0) + 5)
    } else {
      setMaxHeight(0)
    }
  }, [open])

  const Header = useMemo(() => {
    const header = typeof props.header === 'function' ? props.header(open) : props.header

    return React.Children.map(header, (child, index) => React.cloneElement(child, {
      className: `collapse-trigger ${child.className || ''}`,
      onClick: (evt) => {
        setOpen((val) => !val)
        if (child.props.onClick) {
          child.props.onClick(evt)
        }
      },
    }))
  }, [props.header, open, setOpen])

  return (
    <ItemStyled noPlaceholder={!props.placeholder}>
      {Header}
      <AnimationWrapper>
        {props.placeholder && (
          <NonInteractiveInput
            visible={!open}
            onClick={() => !open && setOpen(true)}
            value={props.value}
            placeholder={props.placeholder}
          />
        )}
        <CollapseContentStyled ref={ref} style={{ maxHeight }}>
          {props.children}
        </CollapseContentStyled>
      </AnimationWrapper>
    </ItemStyled>
  )
}
