import {
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonRow,
} from '@ionic/react'
import { Logo } from 'components/common/Logo'
import { ReactNode } from 'react'
import { useSession } from '../../hooks/useSession'

export type GreetingHeaderProps = {
  subtitle?: ReactNode
}

export const GreetingHeader = (props: GreetingHeaderProps) => {
  const { user } = useSession()

  return (
    <IonRow className="ion-padding" style={{ paddingBottom: '4px' }}>
      <IonCol>
        <IonCardTitle style={{ marginBottom: '5px' }}>Hello <span className="vulcan-text-color-primary" style={{ fontWeight: 400 }}>{user?.firstName}</span></IonCardTitle>
        <IonCardSubtitle>{props.subtitle || ' '}</IonCardSubtitle>
      </IonCol>
      <IonCol size="auto">
        <Logo />
      </IonCol>
    </IonRow>
  )
}
