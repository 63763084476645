import { byFieldAsc, byFieldPathAsc } from 'helpers/sortting'
import {
  Fragment,
  useEffect, useMemo, useState,
} from 'react'
import styled from 'styled-components'

import {
  IonIcon,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { useLoading } from 'hooks/useLoading'

import { Select, SelectOption } from 'components/form/Select'
import { Signature } from 'components/form/Signature'
import { useForm } from 'react-hook-form'

import { ModalButton } from 'components/common/ModalButton'
import gql from 'graphql-tag'
import { checkmarkCircleOutline } from 'ionicons/icons'
import {
  compact, isEmpty, isNil, last,
  startCase,
} from 'lodash'
import { DateTime } from 'luxon'
import { useToast } from '../hooks/useToast'
import { InventoryItemVisibility } from '../schema'
import { TermsAndConditionsModal } from './TermsAndConditionsModal'
import { useCollectOrderSignatureMutation, useGetCustomerContactsQuery } from './__generated__/CustomerSignatureModal'
import { GetWorkTicketDataForSignatureQuery, useGetWorkTicketDataForSignatureQuery } from './__generated__/WorkTicketSignatureModal'

const ThinCol = styled(IonCol)`
  padding-top: 2px;
  padding-bottom: 2px;
`

const SubmitButton = styled(IonButton).attrs(() => ({
  children: 'Submit',
  size: 'medium',
  type: 'submit',
}))`
  margin-top: 10px;
  min-width: 70%;
`

const ErrorMessage = styled.span`
  color: var(--ion-color-danger, #ff0000);
  margin: 0.6em 0;
  font-size: 0.8em;
  font-weight: 700;
`

const Label = styled.div`
  font-weight: 700;
  margin-bottom: 3px;
`

const BreakdownRow = styled.tr`
  > td {
    padding: 2px 8px;
  }
  > td:first-child {
    padding-left: 0;
  }
  > td:last-child {
    padding-right: 0;
  }
`

const TermsOfServiceButton = styled(ModalButton).attrs((props) => {
  const title = props.title || 'Terms & Conditions'

  return {
    title,
    content: <span className="tncwrap">
      By clicking submit, you acknowledge you have <br /> read and agree to the <span className="tnclink">{title}</span>
    </span>,
    fill: 'clear',
    size: 'small',
  }
})`
  --padding-top: 5px;
  --padding-bottom: 5px;
  --padding-end: 20px;
  --padding-start: 20px;
  min-height: 44px;
  margin-top: 15px;
  opacity: 1 !important;

  .tncwrap {
    white-space: normal !important;
    color: var(--ion-color-step-700, #4d4d4d);
    min-height: 44px;
    font-size: 13px;
    line-height: 17px;
  }

  .tnclink {
     color: #3880ff;
  }
`

gql`
  query GetWorkTicketDataForSignature($orderId: Int!) {
    order(id: $orderId) {
      ...OrderDataForSignature
      ...OrderWorkTicketData

      assignments {
        id
        roleDetails {
          slug
          sort
        }
        operator {
          id
          firstName
          lastName
        }
      }

      planned {
        equipment {
          id
          name
          displayName
        }
      }

      billable {
        equipment {
          id
          name
          displayName
        }
      }
    }
  }
`

type OrderData = NonNullable<GetWorkTicketDataForSignatureQuery['order']>

const sumMetrics = (details?: OrderData['actuals'][number]) => {
  const byType = (details?.schedule || []).reduce((sumByMetric, entry) => {
    entry.metrics.forEach((metric) => {
      const { value } = metric
      if (isNil(value)) return
      if (typeof value === 'number') {
        if (!sumByMetric.numeric[metric.key]) {
          sumByMetric.numeric[metric.key] = 0
        }
        sumByMetric.numeric[metric.key] += value
      } else {
        sumByMetric.string[metric.key] = compact([
          sumByMetric.string[metric.key],
          metric.value,
        ]).join(', ')
      }
    }, [])
    return sumByMetric
  }, {
    numeric: {},
    string: {},
  } as {
    numeric: Record<string, number>
    string: Record<string, string>
  })

  return {
    ...byType.string,
    ...byType.numeric,
  }
}

interface FormValues {
  personId?: number
  image: string
}

export const WorkTicketSignatureModal = (props: {
  id: number,
  onDismiss?: (completed?: boolean) => void
}) => {
  const { id } = props
  const { withLoading } = useLoading()
  const [presentToast] = useToast()
  const [completed, setCompleted] = useState<boolean>(false)

  const onDismiss = () => {
    props.onDismiss?.(completed)
  }

  const form = useForm<FormValues>()
  const { control, handleSubmit, formState: { errors } } = form

  const orderQuery = useGetWorkTicketDataForSignatureQuery({
    variables: { orderId: id },
  })
  const order = orderQuery?.data?.order
  const orderContacts = order?.contacts || []
  const customerId = order?.customer?.id

  const terms = order?.terms

  const customerContactsQuery = useGetCustomerContactsQuery({
    skip: customerId === undefined,
    variables: { customerId: customerId || -1 },
  })
  const customerContacts = customerContactsQuery?.data?.customers?.[0]?.contacts || []

  const [collectOrderSignatureMutation] = useCollectOrderSignatureMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
  })

  const error = orderQuery?.error || customerContactsQuery?.error

  useEffect(() => {
    if (error) {
      presentToast({
        color: 'danger',
        message: `An error occured while fetching contacts: ${error.message}`,
        duration: 4000,
      })
    }
  }, [error])

  const contacts = useMemo(() => {
    const orderContactsSorted = orderContacts.slice().sort((a, b) => {
      if (a.role?.slug === 'field') return -1
      if (b.role?.slug === 'field') return 1
      if (a.default) return -1
      if (b.default) return 1
      return (
        byFieldPathAsc(['role', 'sort'])(a, b) ||
        byFieldPathAsc(['person', 'firstName'])(a, b) ||
        byFieldPathAsc(['person', 'firstName'])(a, b)
      )
    }).map(({ contact }) => contact)

    const contactIds = orderContactsSorted.map((c) => c.id)

    const customerContactsSorted = customerContacts
      .filter((contact) => !contactIds.includes(contact.id))
      .sort((a, b) => (
        byFieldPathAsc(['person', 'firstName'])(a, b) ||
        byFieldPathAsc(['person', 'firstName'])(a, b)
      ))

    return orderContactsSorted.concat(customerContactsSorted)
  }, [orderContacts, customerContacts])

  useEffect(() => {
    if (!form.getValues('personId')) {
      form.setValue('personId', contacts[0]?.id)
    }
  }, [contacts])

  const onFormSubmit = withLoading(async (data: FormValues) => {
    try {
      const mutationResponse = await collectOrderSignatureMutation({
        variables: {
          data: {
            ...data,
            metadata: {
              upsert: [
                {
                  key: 'workTicket',
                  value: true,
                },
              ],
            },
          },
          where: { id },
        },
      })

      if (mutationResponse.errors) {
        throw new Error(mutationResponse.errors.map((err) => err.message).join('').replace(/[\r\n]+/g, ' '))
      }

      setCompleted(true)
    } catch (err: any) {
      presentToast({
        color: 'danger',
        message: `An error occured, please try again: ${err.message}`,
        duration: 4000,
      })
    }
  })

  const operatorDetails = order?.actuals.find((actual) => actual.subType === 'operator')

  const inventory = useMemo(() => (
    operatorDetails?.inventory?.filter(({ item, quantity }) => {
      const visibility = item.visibility.customerWorkTicket
      return visibility === InventoryItemVisibility.Always || (visibility === InventoryItemVisibility.Auto && quantity)
    })
  ), [operatorDetails?.inventory])

  const metrics = useMemo(() => sumMetrics(operatorDetails), [operatorDetails])

  const schedule = useMemo(() => {
    const timezone = order?.site?.address?.timezone || DateTime.local().zoneName
    const sorted = (operatorDetails?.schedule || []).slice().sort((a, b) => (
      byFieldAsc('startTime')(a, b) || byFieldAsc('scheduleIndex')(a, b)
    ))

    const times = compact(sorted.map((entry) => {
      if (!entry.startTime) return
      return [
        entry.stepDetails.name === 'Prep' ? 'Arrive on Site' : `${entry.stepDetails.name} Start`,
        DateTime.fromISO(entry.startTime).setZone(timezone).toFormat('h:mm a'),
      ]
    }))

    const endTime = last(sorted)?.endTime
    if (endTime) {
      times.push([
        'Depart from Site',
        DateTime.fromISO(endTime).setZone(timezone).toFormat('h:mm a'),
      ])
    }

    return times
  }, [operatorDetails?.schedule])

  const operators = useMemo(() => (
    compact(
      order?.assignments?.sort(byFieldPathAsc(['roleDetails', 'sort'])).map(({ operator }) => (
        compact([operator.firstName, operator.lastName]).join(' ')
      ))
    )

  ), [order?.assignments])

  const equipment = order?.billable?.equipment || order?.planned?.equipment

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Work Ticket #{id}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}> Close </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardContent>
            <IonGrid style={{ padding: '0' }}>
              <IonRow>
                {equipment && (
                  <IonCol size="6">
                    <Label>Billable</Label>
                    {equipment.displayName || equipment.name}
                  </IonCol>
                )}
                {!isEmpty(operators) && (
                  <IonCol size="6">
                    <Label>Operator{operators.length !== 1 && 's'}</Label>
                    {operators.map((operator, i) => <div key={i}>{operator}</div>)}
                  </IonCol>
                )}
                {!isEmpty(metrics) && (
                  <IonCol size="6">
                    {
                      Object.entries(metrics).map(([key, value], i) => (
                        <>
                          <Label>{startCase(key)}</Label>
                          {value}
                        </>
                      ))
                    }
                  </IonCol>
                )}
                {!isEmpty(inventory) && (
                  <IonCol size="6">
                    <Label>System</Label>
                    <table>
                      {inventory?.map((orderItem, i) => (
                        <BreakdownRow key={i}>
                          <td>{isNil(orderItem.quantity) ? null : `${orderItem.quantity}${orderItem.item.displayUnit || 'x'}`}</td>
                          <td>{orderItem.item.name}</td>
                        </BreakdownRow>
                      ))}
                    </table>
                  </IonCol>
                )}

                <ThinCol size="12">
                  <Label> Schedule </Label>
                </ThinCol>

                {schedule.map(([label, time], i) => (
                  <Fragment key={i}>
                    <ThinCol size="6">{label}</ThinCol>
                    <ThinCol size="6">{time}</ThinCol>
                  </Fragment>
                ))}
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>

        {!completed && (
          <form onSubmit={handleSubmit(onFormSubmit)}>
            <IonList>
              <IonItem>
                <IonLabel position="fixed">Name</IonLabel>
                <Select
                  style={{ flexGrow: 1, maxWidth: '100%' }}
                  name="personId"
                  control={control}
                  interface="action-sheet"
                >
                  {contacts.map((contact) => (
                    <SelectOption key={contact.id} value={contact.id}>{contact.firstName} {contact.lastName}</SelectOption>
                  ))}
                </Select>
              </IonItem>
              <IonItem>
                <div style={{ minWidth: '100%' }}>
                  {errors.image &&
                    <ErrorMessage>{errors.image.message}</ErrorMessage>}

                  <Signature
                    name="image"
                    control={control}
                    rules={{
                      required: 'Please sign in the box below',
                    }}
                    style={{
                      minHeight: '200px',
                      width: '100%',
                    }}
                  />
                </div>
              </IonItem>
            </IonList>

            <div className="ion-text-center">
              <TermsOfServiceButton title={terms?.content?.title || 'Terms & Conditions'}>
                <TermsOfServiceButton.Modal>
                  {terms ? <TermsAndConditionsModal terms={terms.content} /> : <IonLoading isOpen />}
                </TermsOfServiceButton.Modal>
              </TermsOfServiceButton>

              <SubmitButton />
            </div>
          </form>
        )}
        {
          completed && (
            <div className="ion-padding ion-text-center" style={{ height: '100%', width: '100%' }} onClick={onDismiss}>
              <div>
                <IonIcon icon={checkmarkCircleOutline} style={{ fontSize: '80px', color: 'var(--ion-color-success, #2fdf75)', marginTop: '10%' }} />
              </div>
              <div style={{ fontSize: '30px', margin: '20px 0 15px' }}>
                Signature Accepted
              </div>

              <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                Please return this device to the Operator.
              </div>
            </div>
          )
        }
      </IonContent>
    </>
  )
}
