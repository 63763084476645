import { IonicSafeString, useIonAlert } from '@ionic/react'
import { useLocalStorageState } from 'ahooks'
import { isNil } from 'lodash'
import { useCallback } from 'react'
import { useToast } from '../../../hooks/useToast'

type UseGeoLocationProps = {
  actionText: 'clock in' | 'clock out' | 'change task',
  showErrorToast?: boolean,
  onError?: (message?: string) => void
}

interface Geolocation {
  coords: Omit<GeolocationCoordinates, 'latitude' | 'longitude'> & {
    lat: NonNullable<GeolocationCoordinates['latitude']>
    lng: NonNullable<GeolocationCoordinates['longitude']>
  }
  timestamp: EpochTimeStamp
}

export const useGeoLocation = (props: UseGeoLocationProps) => {
  const { onError, actionText, showErrorToast } = {
    showErrorToast: true,
    ...props,
  }
  const [presentAlert] = useIonAlert()
  const [presentToast] = useToast()
  const [hasLocationSucceededPreviously, setHasLocationSucceededPreviously] = useLocalStorageState<boolean>('csGeoLocationSucceeded', { defaultValue: false })

  const defaultMessage = `Location services are required to ${actionText}. Please enable location services, or contact your administrator.`

  const checkPermissions = useCallback(() => {
    if (typeof navigator === 'undefined' || !navigator.permissions) return
    return navigator.permissions.query({ name: 'geolocation' })
  }, [])

  const getCurrentPosition = useCallback(async (): Promise<Geolocation | undefined> => {
    const onLocationError = (message?: string) => {
      setHasLocationSucceededPreviously(false)
      const msg = message || defaultMessage
      onError?.(msg)
      if (showErrorToast) {
        presentToast({
          color: 'danger',
          message: message || `Location services are required to ${actionText}. Please enable location services or contact your administrator.`,
        })
      }
    }

    const geolocation = typeof navigator !== 'undefined' ? navigator.geolocation : undefined
    if (!geolocation) {
      onLocationError(`Location services are required to ${actionText}, but are not available on this device. Please enable location services to clock in.`)
      return
    }

    const permissions = await checkPermissions()
    if (permissions && permissions.state === 'denied') {
      onLocationError(`Location services are required to ${actionText}, but are disabled. Please enable location services in your browser to clock in.`)
      return
    }
    if (permissions && permissions.state === 'prompt' && !hasLocationSucceededPreviously) {
      await new Promise((onDidDismiss) => {
        presentAlert({
          header: 'Location Required',
          message: new IonicSafeString(`Your organization requires your location in order to ${actionText}. <br /> <br /> To ${actionText}, you must Allow your precise location if asked.`),
          buttons: [
            {
              text: 'Continue',
            },
          ],
          onDidDismiss,
        })
      })
    }

    try {
      const position = await new Promise<GeolocationPosition | undefined>((resolve, reject) => {
        geolocation.getCurrentPosition(
          resolve,
          (error) => {
            const message = error.message ? `Could not determine location: ${error.message}` : defaultMessage
            reject(message)
          },
          {
            enableHighAccuracy: true,
            maximumAge: 0,
          }
        )
      })
      if (!position) throw new Error('Could not determine location')
      const { timestamp, coords } = position
      if (isNil(coords.latitude) || isNil(coords.longitude)) {
        throw new Error('Could not determine location')
      }
      setHasLocationSucceededPreviously(true)
      return {
        timestamp,
        coords: {
          lat: coords.latitude,
          lng: coords.longitude,
          accuracy: coords.accuracy,
          altitude: coords.altitude,
          altitudeAccuracy: coords.altitudeAccuracy,
          heading: coords.heading,
          speed: coords.speed,
        },
      }
    } catch (err: any) {
      onLocationError(err.message)
    }
  }, [])

  return { getCurrentPosition }
}
