import { IonLoading } from '@ionic/react'
import { useCreation } from 'ahooks'
import React, {
  useContext, useEffect, useRef, useState,
} from 'react'
import { Subject } from 'rxjs'

interface LoadingMap {
  [id: string]: boolean
}

interface LoadingFunctions {
  showLoading: (id: string) => void
  hideLoading: (id: string) => void
}

const LoadingContextObserver = React.createContext(new Subject<['show' | 'hide', string]>())

const LoadingContextFunctions: React.Context<LoadingFunctions> = React.createContext({
  showLoading: (_) => { },
  hideLoading: (_) => { },
})

const LoadingViaObservable = () => {
  const loadingObservable = useContext(LoadingContextObserver)
  const [loading, setLoading] = useState<LoadingMap>({})
  const isLoading = Object.values(loading).some((v: boolean) => v)

  loadingObservable.subscribe(([action, id]) => {
    setLoading((previousLoading: LoadingMap) => {
      if (action === 'show' && previousLoading[id] === true) {
        return previousLoading
      }

      if (action === 'hide' && previousLoading[id] !== true) {
        return previousLoading
      }

      const newLoading: LoadingMap = { ...previousLoading }

      if (action === 'show') {
        newLoading[id] = true
      }
      if (action === 'hide') {
        delete newLoading[id]
      }

      return newLoading
    })
  })

  return (
    <IonLoading
      isOpen={isLoading}
      message="Please wait..."
    />
  )
}

export const LoadingProvider: React.FC = (props) => {
  const loadingObservable = useCreation(
    () => new Subject<['show' | 'hide', string]>(),
    []
  )

  const loadingFunctions = useCreation(() => {
    const showLoading = (id: string) => {
      loadingObservable.next(['show', id])
    }

    const hideLoading = (id: string) => {
      loadingObservable.next(['hide', id])
    }

    return { showLoading, hideLoading }
  }, [loadingObservable])

  return (
    <>
      <LoadingContextObserver.Provider value={loadingObservable}>
        <LoadingViaObservable />
        <LoadingContextFunctions.Provider value={loadingFunctions}>
          {props.children}
        </LoadingContextFunctions.Provider>
      </LoadingContextObserver.Provider>
    </>
  )
}

export function useLoading() {
  const componentId = useRef(Math.random().toString(20).substring(2))
  const loadingContext = useContext(LoadingContextFunctions)

  const showLoading = () => {
    loadingContext.showLoading(componentId.current)
  }

  const hideLoading = () => {
    loadingContext.hideLoading(componentId.current)
  }

  const withLoading = <T extends Array<any>, U>(fn: (...args: T) => Promise<U>) => async (...args: T): Promise<U> => {
    const id = Math.random().toString(20).substring(2)

    loadingContext.showLoading(id)
    let toReturn: U
    try {
      toReturn = await fn(...args)
    } finally {
      loadingContext.hideLoading(id)
    }
    return toReturn
  }

  useEffect(() => hideLoading, []) // eslint-disable-line react-hooks/exhaustive-deps

  return { showLoading, hideLoading, withLoading }
}
