import { TimeClock } from 'hooks/timeclock/useTimeClock'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'

const ONE_MIN_IN_MS = 60 * 1000

const DurationLive = ({ startTime }: { startTime: NonNullable<TimeClock['startTime']> }) => {
  const [endTime, setEndTime] = useState<DateTime>(DateTime.utc())

  useEffect(() => {
    if (endTime) {
      setEndTime(DateTime.utc())
    }
  }, [startTime.toMillis()])

  useEffect(() => {
    if (!endTime) return

    const msDiff = endTime.toMillis() - startTime.toMillis()
    const msTillNextMinute = ONE_MIN_IN_MS - (msDiff % ONE_MIN_IN_MS)

    const timeout = setTimeout(() => {
      setEndTime(DateTime.utc())
    }, msTillNextMinute)
    return () => clearTimeout(timeout)
  }, [endTime.toMillis()])

  const diff = endTime.diff(startTime)

  if (diff.as('minutes') < 0) {
    return <>00:00</>
  }

  return <>{diff.toFormat('hh:mm')}</>
}

const DurationStatic = (
  {
    startTime,
    endTime,
  }: {
    startTime: NonNullable<TimeClock['startTime']>,
    endTime: NonNullable<TimeClock['endTime']>
  }
) => (
  <>{endTime.diff(startTime).toFormat('hh:mm')}</>
)

type DurationProps = Partial<Pick<TimeClock, 'startTime' | 'endTime'>> & {
  showEmpty?: true
}

export const Duration = (props: DurationProps) => {
  const { startTime, endTime, showEmpty } = props
  if (startTime && endTime) {
    return <DurationStatic startTime={startTime} endTime={endTime} />
  }
  if (startTime) {
    return <DurationLive startTime={startTime} />
  }
  if (showEmpty) {
    const now = DateTime.utc()
    return <DurationStatic startTime={now} endTime={now} />
  }
  return null
}
