import { gql } from '@apollo/client'
import { IonButton, IonIcon } from '@ionic/react'
import { useNavigateInteractionHandler } from 'hooks/useNavigateInteractionHandler'
import { navigateCircle } from 'ionicons/icons'
import { ComponentProps, useMemo } from 'react'
import { Route, RouteStatus, RouteWaypointStatus } from 'schema'
import { NavigateRouteButtonDataFragment, useGetNavigateRouteButtonDataQuery } from './__generated__/NavigateRouteButton'

gql`
  fragment NavigateRouteButtonData on Route {
    id
    status
    waypoints {
      id
      status
      address {
        ...AddressFields
      }
    }
  }
`

gql`
  query GetNavigateRouteButtonData($routeId: Int!) {
    route(id: $routeId) {
      ...NavigateRouteButtonData
    }
  }
`

export interface NavigateRouteButtonProps extends Omit<ComponentProps<typeof IonButton>, 'id'> {
  id: Route['id']
}

const shouldVisitWaypoint = (waypoint: NavigateRouteButtonDataFragment['waypoints'][0]) => (
  [RouteWaypointStatus.EnRoute, RouteWaypointStatus.Scheduled].includes(waypoint.status)
)

export const NavigateRouteButton = ({
  id,
  ...rest
}: NavigateRouteButtonProps) => {
  const { data } = useGetNavigateRouteButtonDataQuery({
    fetchPolicy: 'cache-first',
    variables: {
      routeId: id,
    },
  })

  const route = data?.route

  const waypointsToNavigate = useMemo(() => {
    const waypoints = route?.waypoints || []
    if (waypoints.length === 0) return []

    let toNavigate = waypoints.slice()

    if (route?.status === RouteStatus.InProgress) {
      const nextNavigateableWaypoint = toNavigate.findIndex(shouldVisitWaypoint)
      toNavigate = toNavigate.slice(nextNavigateableWaypoint, nextNavigateableWaypoint + 1)
    }

    if (
      toNavigate.length > 1 &&
      toNavigate[0].address.id === toNavigate[toNavigate.length - 1].address.id
    ) {
      toNavigate.pop()
    }

    return toNavigate
  }, [route])

  const { ref } = useNavigateInteractionHandler<HTMLIonButtonElement>({
    link: (navigator) => {
      if (waypointsToNavigate.length === 0) return null
      let toNavigate = waypointsToNavigate

      if (navigator.supportedCount < toNavigate.length) {
        const firstDestinationAddress = toNavigate.findIndex(shouldVisitWaypoint)
        toNavigate = toNavigate.slice(firstDestinationAddress, firstDestinationAddress + navigator.supportedCount)
      }

      const addresses = toNavigate.map(({ address }) => address)

      return navigator.fn(addresses)
    },
  })

  return (
    <IonButton
      expand="block"
      target="_blank"
      ref={ref}
      {...rest}
    >
      {rest.children ?
        rest.children
        : (
          <span>
            <IonIcon slot="start" icon={navigateCircle} style={{ margin: 0 }} />
          </span>
        )}
    </IonButton>
  )
}
